import 'firebase/auth';
import 'firebase/firestore';
import parse from 'html-react-parser';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import FieldTitle from '../../components/common/FieldTitle';
import UserAvatar from '../../images/user-avatar.png';
import { parseHTMLText } from '../../utils/candidate-helper';
import getExperienceFromEmployment from '../../utils/getExperienceFromEmployment';
import ProfileImage from './profile/profile-image';

function CandidateDetailsHeader(props) {
  const { candidate } = props;
  return (
    <div className="flex w-full items-center mb-xl">
      <ProfileImage isDisabled={candidate?.is_disabled} approved={true} src={candidate.profile_picture_thumb_url ? candidate.profile_picture_thumb_url : UserAvatar} />
      <div className="flex flex-col font-medium text-darkblue">
        <div className="flex items-center">
          <div className="text-darkblue mr-md">{candidate.name ? candidate.name : ''}</div>
        </div>
        <div className="flex items-center">
          <div className="text-darkgray mr-md">{candidate.emails && candidate.emails.length > 0 ? candidate.emails[0].value : ''}</div>
        </div>
      </div>
    </div>
  );
}
export { CandidateDetailsHeader };

export default function LoxoCandidateDetail({ state }) {
  const [modal, setModal] = state;
  const [fadeDelay, setFadeDelay] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    if (modal.open) {
      setFadeDelay(true);
    } else {
      setTimeout(() => {
        setFadeDelay(false);
      }, 300);
    }
  }, [modal]);

  const attributes = modal.data && modal.data.candidate.all_raw_tags && modal.data.candidate.all_raw_tags.split(',');

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const experience = getExperienceFromEmployment({ employment: _.get(modal, 'data.employment', []) });

  return (
    <React.Fragment>
      <div
        className={`fixed inset-0 items-center justify-center`}
        style={{ transition: 'opacity 0.3s ease-in-out', opacity: modal.open ? 1 : 0, zIndex: 3000000, display: fadeDelay ? 'flex' : 'none', backgroundColor: 'none', left: '57%' }}
      >
        <div ref={modalRef} className="bg-white px-xl py-md rounded shadow flex flex-col" style={{ height: '100%', width: 745, overflowY: 'scroll', overflowX: 'hidden' }}>
          <div style={{ display: 'inline-flex' }} className="text-lg font-medium pb-sm border-b mb-lg">
            <div>Candidate Detail</div>
            <div style={{ marginLeft: 450, cursor: 'pointer' }} onClick={() => setModal({ ...modal, open: false, data: null, reset: true })}>
              x
            </div>
          </div>
          <div className="flex flex-col w-full py-lg container">
            <div className="flex flex-col items-center flex-1 lg:items-start lg:flex-row ">
              <div className="bg-white rounded flex-1 mb-lg flex text-sm">
                <div className="flex flex-wrap relative w-full" style={{ maxWidth: '100%' }}>
                  <CandidateDetailsHeader candidate={modal.data ? modal.data.candidate : ''} />
                  <div className=" flex flex-col font-medium w-1/2">
                    <div className="flex items-center capitalize text-darkgray pb-md">phone</div>
                    <div className="text-darkblue pb-xl pr-lg ">
                      {modal.data && modal.data.candidate.phones && modal.data.candidate.phones.length > 0 ? modal.data.candidate.phones[0].value : ''}
                    </div>
                  </div>
                  <div className=" flex flex-col font-medium w-1/2">
                    <div className="flex items-center capitalize text-darkgray pb-md">linkedin</div>
                    <div className={`text-darkblue pb-xl pr-lg`}>
                      <a className="hover:underline" href={modal.data ? modal.data.candidate.linkedin_url : ''} target="_blank" rel="noreferrer">
                        {modal.data ? modal.data.candidate.linkedin_url : 'N/A'}
                      </a>
                    </div>
                  </div>
                  <div className=" flex flex-col font-medium w-1/2">
                    <div className="flex items-center capitalize text-darkgray pb-md">location</div>
                    <div className="text-darkblue pb-xl pr-lg ">
                      {modal.data && modal.data.candidate.city ? modal.data.candidate.city + ' ' + modal.data.candidate.state : 'N/A'}
                    </div>
                  </div>
                  <div className=" flex flex-col font-medium w-1/2">
                    <div className="flex items-center capitalize text-darkgray pb-md">salary</div>
                    <div className="text-darkblue pb-xl pr-lg ">
                      {modal.data && parseInt(modal.data.candidate.compensation) ? `$ ${numberWithCommas(Math.round(modal.data.candidate.compensation))} +` : 'Negotiable'}
                    </div>
                  </div>
                  <div className=" flex flex-col font-medium w-1/2">
                    <div className="flex items-center capitalize text-darkgray pb-md">Experience</div>
                    <div className="text-darkblue pb-xl pr-lg ">{experience ? `${experience} ${experience > 1 ? 'years' : 'year'}` : `0 years`}</div>
                  </div>
                  <div className=" flex flex-col font-medium w-1/2">
                    <div className="flex items-center capitalize text-darkgray pb-md">Created Date</div>
                    <div className="text-darkblue pb-xl pr-lg ">
                      {modal.data && modal.data.candidate.created_at ? moment(modal.data.candidate.created_at).format('MM/DD/YYYY') : 'N/A'}
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex">
                      <div className="flex-1">
                        <div className="text-darkblue w-full flex items-center font-medium flex justify-between" style={{ fontSize: 18, marginTop: 40, fontWeight: 400 }}>
                          <div>Job Preference</div>
                          <div className="flex-1 border-b ml-md"></div>
                        </div>
                      </div>
                    </div>
                    <div className="text-darkblue pb-xl pr-lg ">
                      <div className="flex flex-wrap">
                        {_.get(modal, 'data.candidate.custom_hierarchy_2', []).map((a) => {
                          return (
                            <div
                              className={`${'bg-lightgray text-darkgray'} mt-md shadow px-md flex items-center mr-sm font-medium`}
                              style={{ fontSize: 12, height: 28, borderRadius: 28 }}
                            >
                              {a.value}
                            </div>
                          );
                        }) || 'N/A'}
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex">
                      <div className="flex-1">
                        <div className="text-darkblue w-full flex items-center font-medium flex justify-between" style={{ fontSize: 18, marginTop: 40, fontWeight: 400 }}>
                          <div>Employment</div>
                          <div className="flex-1 border-b ml-md"></div>
                        </div>
                      </div>
                    </div>
                    {modal.data &&
                      modal.data.employment &&
                      modal.data.employment.map((emp) => (
                        <div key={emp.id.toString()} style={{ paddingTop: 20 }}>
                          <div className="flex flex-wrap">
                            <div className="w-full lg:w-auto flex justify-center" style={{ width: '100%' }}>
                              <div className="flex flex-1 flex-col font-medium" style={{ maxWidth: '50%' }}>
                                <div>{emp.title}</div>
                              </div>
                              <div className="flex flex-1 font-medium" style={{ maxWidth: '50%', paddingLeft: 30 }}>
                                <div className="flex flex-col">
                                  <div>{emp.company.name}</div>
                                  <div className="text-darkgray" style={{ fontSize: 14 }}>
                                    {months[emp.month - 1] + ' ' + emp.year} - {emp.end_month ? months[emp.end_month - 1] + ' ' + emp.end_year : 'Present'}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-darkgray" style={{ fontSize: 14, paddingTop: 15 }}>
                            <p>{emp.description ? parse(parseHTMLText(emp.description)) : ''}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="w-full">
                    <div className="flex">
                      <div className="flex-1">
                        <div className="text-darkblue w-full flex items-center font-medium flex justify-between" style={{ fontSize: 18, marginTop: 40, fontWeight: 400 }}>
                          <div>Education</div>
                          <div className="flex-1 border-b ml-md"></div>
                        </div>
                      </div>
                    </div>
                    {modal.data &&
                      modal.data.education &&
                      modal.data.education.map((edu) => {
                        const endDate = moment()
                          .set({ year: edu.year, month: edu.month || 0 })
                          .startOf('year')
                          .format('MMM YYYY');

                        return (
                          <div className="text-darkblue font-medium" style={{ paddingTop: 15 }}>
                            <div style={{ fontSize: 16 }}>{edu.degree}</div>
                            <div className="text-darkgray" style={{ fontSize: 14 }}>
                              <div style={{ display: 'inline-block', marginRight: 8 }}>{edu.school}</div>
                              <div style={{ fontSize: 26, display: 'inline-block', position: 'relative', top: 2, lineHeight: '14px' }}>•</div> {endDate}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="w-full relative">
                    <div className="flex">
                      <div className="flex-1">
                        <FieldTitle title="Candidate Attributes" />
                      </div>
                    </div>
                    <div className="flex flex-wrap">
                      {attributes &&
                        attributes.map((skill) => (
                          <div
                            key={skill.toString()}
                            className={`bg-lightgray text-darkgray mt-md shadow px-md flex items-center mr-sm font-medium`}
                            style={{ fontSize: 12, height: 28, borderRadius: 28 }}
                          >
                            {skill}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modal.open && (
        <div
          onClick={() => setModal({ ...modal, open: false, data: null, reset: true })}
          style={{ position: 'fixed', top: 0, right: 0, height: '100%', width: '100%', background: 'black', opacity: '0.5', zIndex: 1000001 }}
        ></div>
      )}
    </React.Fragment>
  );
}
