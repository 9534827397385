import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Link } from 'gatsby';
import parse from 'html-react-parser';
import { head, isEmpty, propOr } from 'ramda';
import AdminActions from '../../../components/admin/actions/admin-actions';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useSnackbar } from 'react-simple-snackbar';
import { Arrow, RequestInterviewButton } from '../../../components/common';
import DeleteUsersButton from '../../../components/common/DeleteUsersButton';
import FieldTitle from '../../../components/common/FieldTitle';
import InterviewModal from '../../../components/common/InterviewModal';
import { EXPRESS_SERVER_URL } from '../../../config';
import { UserRoleContext } from '../../../context';
import { ApolloContext } from '../../../context/Apollo';
import { TextInput } from '../../../forms/fields';
import RichTextArea from '../../../forms/fields/RichTextArea';
import useCompanyCheck from '../../../hooks/useCompanyCheck';
import ChatIcon from '../../../images/chat.svg';
import CheckIcon from '../../../images/check-solid.svg';
import EditIcon from '../../../images/edit.svg';
import EyeIcon from '../../../images/eye-solid.svg';
import CloseIcon from '../../../images/times-solid.svg';
import { bioLorem, DEFAULT_EMAIL_INVITE_BODY, USER_ROLES } from '../../../utils/constants';
import { formatSalary } from '../../../utils/formatter';
import MatchCandidate from '../../recruiters/recruiter-details/MatchCandidate';
import { generateInviteCode } from './../../../utils/getId';
import { generateInviteUrl } from './../../../utils/google.helper';
import AdditionalNotes from './additional-notes';
import AddToProjectButton from './AddToProjectButton';
import ApprovedCompanies from './ApprovedCompanies';
import CompanyCandidateDetails from './candidate-details-comp';
import CandidateDetailsHeader from './CandidateDetailsHeader';
import CandidateEmployment from './CandidateEmployment';
import CandidateSocial from './CandidateSocial';
import EducationEntry from './education-entry';
import InterestedJobs from './InterestedJobs';
import MobileCandidateOptions from './MobileCandidateOptions';
import ProfileChangeUI from './ProfileChangeUI';
import { prepareUrl } from '../../../utils/formatter';
import './candidate-details.css';
import AgencyMatchCandidate from '../../agencies/agencies-details/AgencyMatchCandidate';

const RECRUITER_PROFILE = gql`
  query getRecruiterProfile($recruiter_id: String) {
    recruiter(where: { id: { _eq: $recruiter_id } }) {
      id
      availability
      company_id
      company {
        id
        name
      }
      phone
      position
      profilePhoto
      title
      industries
      name
      email
      recruiter_invitation_templates {
        subject
        body
        id
        recruiter_id
      }
    }
  }
`;

const GET_PROFILE_CHANGE_REQUEST = gql`
  subscription getProfileChangeRequests($candidate_id: String) {
    candidate_profile_change_request(where: { candidate_id: { _eq: $candidate_id } }) {
      field
      value
    }
  }
`;

const GET_CANDIDATE = gql`
  subscription getCandidateDetails($id: String) {
    candidate(where: { id: { _eq: $id } }) {
      id
      adminID
      profilePictureURL
      name
      email
      phone
      resumeFileName
      resumeURL
      linkedin
      referralName
      recruiter {
        id
        name
      }
      location
      salaryMax
      salaryMin
      facebook
      instagram
      twitter
      experience
      bio
      employment
      education
      certifications
      attributes
      preferredSkills
      notes
      locationPreferences
      interestedCity
      approved
      adminID
      loxo_id
      invite_code
      invite_deeplink
      invite_code_used_on
      is_login
      device_token
      recruiter_id
    }
  }
`;

const APPROVE_PROFILE_CHANGE = gql`
  mutation approveProfileChangeRequest($candidate_id: String, $field: String, $change: candidate_set_input) {
    update_candidate(where: { id: { _eq: $candidate_id } }, _set: $change) {
      affected_rows
    }
    delete_candidate_profile_change_request(where: { field: { _eq: $field }, candidate: { id: { _eq: $candidate_id } } }) {
      affected_rows
    }
  }
`;

const DECLINE_PROFILE_CHANGE = gql`
  mutation approveProfileChangeRequest($candidate_id: String, $field: String) {
    delete_candidate_profile_change_request(where: { field: { _eq: $field }, candidate: { id: { _eq: $candidate_id } } }) {
      affected_rows
    }
  }
`;

const GET_COMPANY_ID = gql`
  query getCompanyID($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      remainingCredit
      subscription_type
    }
  }
`;

const GET_COMPANY_REQUESTS = gql`
  query companyRequests($admin_id: String) {
    company_request(where: { company: { adminID: { _eq: $admin_id } } }) {
      status
      candidate_id
    }
  }
`;

export default function CandidateDetails({ location, navigate }) {
  location.state = location.state || {
    candidate: {
      id: location.pathname.replace('/candidates/', '')
    }
  };
  const [candidate, setCandidate] = useState();
  const [userRole] = useContext(UserRoleContext);
  const isAdmin = userRole === 'admin';
  const isAgency = userRole === 'agency';
  const candidateId = location.state && location.state.candidate && location.state.candidate.id;
  const { data } = useSubscription(GET_CANDIDATE, {
    variables: { id: candidateId },
    fetchPolicy: 'no-cache',
  });

  const { data: profileChanges } = useSubscription(GET_PROFILE_CHANGE_REQUEST, {
    variables: { candidate_id: candidateId },
  });

  const [approveProfileChange] = useMutation(APPROVE_PROFILE_CHANGE);
  const [declineProfileChange] = useMutation(DECLINE_PROFILE_CHANGE);
  const [changes, setChanges] = useState({});
  const [approvedChanges, setApprovedChanges] = useState({});
  const [modal, setModal] = useState({ data: null, open: false });

  const adminId = firebase.auth().currentUser && firebase.auth().currentUser.uid;
  const { data: company } = useQuery(GET_COMPANY_ID, {
    variables: { admin_id: adminId },
  });
  const companyID = company && company.company[0] && company.company[0].id;
  const subscriptionType = company && company.company[0] && company.company[0].subscription_type;
  const { isCompany, loading } = useCompanyCheck();
  const companyUser = !loading && isCompany;
  const recruiterUser = !loading && !isCompany;
  const { data: requests } = useQuery(GET_COMPANY_REQUESTS, {
    variables: { admin_id: adminId },
  });

  const [isInviteModalOpen, setIsInviteModalOpen] = React.useState(false);
  const { apolloClient } = useContext(ApolloContext);
  const [inviteEmailSubject, setInviteEmailSubject] = useState('Inviting you to CherryPicker');
  const [inviteEmailBody, setInviteEmailBody] = useState(DEFAULT_EMAIL_INVITE_BODY);

  const { data: templateData } = useQuery(
    RECRUITER_PROFILE,
    { variables: { recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid } }
  );

  useEffect(() => {
    const data = templateData?.recruiter?.[0]?.recruiter_invitation_templates?.[0]
    const { subject, body } = data || {};
    setInviteEmailSubject(subject);
    setInviteEmailBody(body);
  }, [templateData])

  const handleInviteModalOpen = async () => {
    setInviteLoading(true);

    // Fallback for old candidates as invite code is not yet generated for them.
    if (!candidate.invite_code || !candidate.invite_deeplink || candidate.invite_code_used_on) {
      // Generate invite code and deeplink
      const inviteCode = generateInviteCode();

      const deeplink = await generateInviteUrl({ inviteCode });

      // Save generated code in database
      const candidateUpdated = await apolloClient.mutate({
        mutation: gql`
          mutation UpdateCandidateInviteCode {
            update_candidate(where: { id: { _eq: "${candidate.id}" } }, _set: { invite_code: "${inviteCode}", invite_code_used_on: null, invite_deeplink: "${deeplink}" }) {
              affected_rows
              returning {
                id
                profilePictureURL
                name
                email
                phone
                resumeFileName
                resumeURL
                linkedin
                referralName
                recruiter {
                  id
                  name
                }
                location
                salaryMax
                salaryMin
                facebook
                instagram
                twitter
                experience
                bio
                employment
                education
                certifications
                attributes
                preferredSkills
                notes
                locationPreferences
                interestedCity
                approved
                adminID
                loxo_id
                invite_code
                invite_deeplink
                invite_code_used_on
                recruiter_id
              }
            }
          }
        `,
      });

      const jsonFields = ['location', 'employment', 'education', 'attributes', 'locationPreferences', 'certifications', 'preferredSkills'];
      const newCandidate = Object.entries(candidateUpdated.data.update_candidate.returning[0]).map(([label, value]) => {
        if (jsonFields.includes(label) && typeof value === 'string' && value !== '') {
          return [label, JSON.parse(value) || {}];
        } else {
          return [label, value];
        }
      });

      const tempCandidate = candidateUpdated.data.update_candidate.returning[0];
      setInviteEmailSubject(
        inviteEmailSubject.replaceAll('{{_CANDIDATE_NAME_}}', tempCandidate.name)
          .replaceAll('{{candidate_name}}', (tempCandidate.name || '').split(' ')[0])
      )

      setInviteEmailBody(
        inviteEmailBody.replaceAll('{{_CANDIDATE_NAME_}}', tempCandidate.name)
          .replaceAll('{{candidate_name}}', (tempCandidate.name || '').split(' ')[0])
          .replaceAll('{{invitation_code}}', tempCandidate.invite_code)
          .replaceAll('{{_INVITE_CODE_LINK_}}', tempCandidate.invite_deeplink),
      );
      setCandidate(Object.fromEntries(newCandidate));
    }else {
      setInviteEmailBody(
        inviteEmailBody.replaceAll('{{_CANDIDATE_NAME_}}', candidate.name)
          .replaceAll('{{candidate_name}}', (candidate.name || '').split(' ')[0])
          .replaceAll('{{invitation_code}}', candidate.invite_code)
          .replaceAll('{{_INVITE_CODE_LINK_}}', candidate.invite_deeplink),
      );
      setInviteEmailSubject(
        inviteEmailSubject.replaceAll('{{_CANDIDATE_NAME_}}', candidate.name)
          .replaceAll('{{candidate_name}}', (candidate.name || '').split(' ')[0])
      )
    }

    setIsInviteModalOpen(true);
  };

  const handleInviteModalClose = () => {
    setInviteLoading(false);

    setIsInviteModalOpen(false);
  };

  const request = requests && candidate && requests.company_request.filter((req) => req.candidate_id === candidate.id);
  const status = request && !isEmpty(request) && propOr('pending', 'status')(head(request));

  const mutualInterest = status && status === 'approved';

  useEffect(() => {
    if (data && data.candidate.length) {
      // Some fields returning stringified JSON on update... Temporary fix
      const jsonFields = ['location', 'employment', 'education', 'attributes', 'locationPreferences', 'certifications', 'preferredSkills'];
      const newCandidate = Object.entries(data.candidate[0]).map(([label, value]) => {
        if (jsonFields.includes(label) && typeof value === 'string' && value !== '') {
          return [label, JSON.parse(value) || {}];
        } else {
          return [label, value];
        }
      });
      setCandidate(Object.fromEntries(newCandidate));
    }
  }, [data]);

  useEffect(() => {
    // if (candidate) {
    //   setInviteEmailBody(
    //     DEFAULT_EMAIL_INVITE_BODY.replaceAll('##_CANDIDATE_NAME_##', candidate.name)
    //       .replaceAll('{{candidate_name}}', (candidate.name || '').split(' ')[0])
    //       .replaceAll('{{invitation_code}}', candidate.invite_code)
    //       .replaceAll('##_INVITE_CODE_LINK_##', candidate.invite_deeplink),
    //   );
    // }
  }, [candidate]);

  useEffect(() => {
    if (profileChanges) {
      const allChanges = {};
      profileChanges.candidate_profile_change_request.forEach(({ field, value }) => {
        if (field === 'profilePictureURL') {
          allChanges['profilePictureURL'] = value;
        } else if (field === 'locationPreferences') {
          allChanges['locationPreferences'] = value;
        } else if (field === 'interestedCity') {
          allChanges['interestedCity'] = value;
        } else if (field === 'preferredSkills') {
          allChanges['preferredSkills'] = value;
        } else {
          allChanges[field.toLowerCase()] = value;
        }
      });
      setChanges(allChanges);
    }
  }, [profileChanges]);

  const renderValue = (label, value) => {
    switch (label) {
      case 'recruiter':
        return value && value.name;
      case 'location':
        return value && value.city;
      case 'experience':
        return value === 1 ? `${value} year` : `${value} years`;
      case 'resume':
        /* return value.fileName */
        return null;
      case 'linkedin':
        return value ? (
          <a className="hover:underline" href={value.includes('linkedin.com/in') ? value : `https://www.linkedin.com/in/${value}/`} target="_blank" rel="noreferrer">
            {value.includes('linkedin.com/in') ? value : `@${value}`}
          </a>
        ) : (
          ''
        );
      default:
        return value;
    }
  };

  async function approveProfileChangeRequest(label) {
    if (label === 'salary') {
      setApprovedChanges({
        ...approvedChanges,
        salaryMax: changes.salarymax,
        salaryMin: changes.salarymin,
      });
      approveProfileChange({
        variables: {
          candidate_id: candidate.id,
          field: 'salaryMin',
          change: { salaryMin: changes.salarymin },
        },
      });
      approveProfileChange({
        variables: {
          candidate_id: candidate.id,
          field: 'salaryMax',
          change: { salaryMax: changes.salarymax },
        },
      });
    } else {
      setApprovedChanges({ ...approvedChanges, [label]: changes[label] });
      approveProfileChange({
        variables: {
          candidate_id: candidate.id,
          field: label,
          change: {
            [label]: changes[label],
          },
        },
      });
    }
    fetch(`${EXPRESS_SERVER_URL}/approvedProfileChangeRequestNotification`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ candidate_id: candidate.id }),
    }).then((response) => response.json());
  }

  function declineProfileChangeRequest(label) {
    declineProfileChange({
      variables: {
        candidate_id: candidate.id,
        field: label,
      },
    });
    setChanges(Object.fromEntries(Object.entries(changes).filter(([field]) => field !== label)));
  }

  const [inviteLoading, setInviteLoading] = useState(false);
  const [openSnackbar] = useSnackbar();

  const inviteCandidate = async () => {
    handleInviteModalClose();

    openSnackbar('Sending invite...');

    setInviteLoading(true);

    const email = candidate.email;
    const password = Math.random()
      .toString(36)
      .slice(-8);

    try {
      // 1. Get firebase details
      const getUserFirebaseAccountRequest = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      };
      const userFirebaseResponse = await await fetch(`${EXPRESS_SERVER_URL}/getUserFirebaseAccount`, getUserFirebaseAccountRequest);

      // 2. If details are not available via firebase, Create a new one.
      if (userFirebaseResponse && userFirebaseResponse?.status !== 200) {
        try {
          const createFirebaseUserRequest = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password, name: candidate.name }),
          };
          // 2.a. Create firebase account
          await fetch(`${EXPRESS_SERVER_URL}/createCandidateFirebaseAccount`, createFirebaseUserRequest);
        } catch (error) {
          console.error('ERROR_CREATE_FIREBASE_ACCOUNT', error);
          openSnackbar('Invite not sent. Please try again.');
          setInviteLoading(false);
        }
      }

      // 3. Send invite via email
      try {
        const text = 'dummy';
        const sendInviteEmailRequest = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, subject: inviteEmailSubject, content: inviteEmailBody, text }),
        };
        const sendInviteEmailResponse = await fetch(`${EXPRESS_SERVER_URL}/inviteCandidateEmail`, sendInviteEmailRequest);
        const sendInviteEmailResponseJSON = await sendInviteEmailResponse.json();
        openSnackbar(sendInviteEmailResponseJSON?.statusCode === 200 ? 'Invite sent' : 'Invite not sent. Please try again.');
        setInviteLoading(false);
      } catch (error) {
        console.error('ERROR_SEND_EMAIL_INVITE', error);
        openSnackbar('Invite not sent. Please try again.');
        setInviteLoading(false);
      }
    } catch (error) {
      console.error('ERROR_SEND_INVITE', error);
      openSnackbar('Invite not sent. Please try again.');
      setInviteLoading(false);
    }

  };

  let sendInvitationButtonText = 'Send Invitation';

  if (candidate && candidate.invite_code_used_on) {
    sendInvitationButtonText = 'Send Another Invitation';
  }

  return companyUser ? (
    <CompanyCandidateDetails candidate={candidate} approved={mutualInterest} status={status} companyID={companyID} />
  ) : loading ? null : (
    <div className="flex flex-col w-full py-lg container">
      <InterviewModal state={[modal, setModal]} />
      <div className="flex justify-start">
        <div
          className="cursor-pointer text-darkgray flex items-center capitalize"
          style={{ fontSize: 12 }}
          /*
          onClick={() =>
            location.state.from
              ? navigate(location.state.from)
              : companyUser
                ? navigate('/dashboard/find-candidate')
                : isAdmin
                  ? navigate('/dashboard/candidates')
                  : navigate('/dashboard/candidates')
          }
          */
          onClick={() => window.history.back()}
        >
          <Arrow color="darkgray" style={{ height: 15, marginRight: 10 }} /> back

          {/*
          {location.state.from ? location.state.from.split('/')[location.state.from.split('/').length - 1] : companyUser ? 'Find Candidates' : 'Candidates'} */}
        </div>
      </div>
      <div className="flex flex-col items-center flex-1 lg:items-start lg:flex-row">
        <div className="flex flex-col w-full lg:mr-xl" style={{ maxWidth: 530, minHeight: 500 }}>
          <div className="flex items-center py-sm mb-sm flex-start">
            <div className="text-darkblue font-medium" style={{ fontSize: 21 }}>
              Candidate Details
            </div>
            {companyUser && candidate && (
              <div className="flex-1 flex justify-center" style={{ marginLeft: 10 }}>
                <RequestInterviewButton
                  candidateID={candidate.id}
                  candidate={candidate}
                  status={status}
                  companyID={companyID}
                  subscriptionType={subscriptionType}
                  bg="transparent"
                  hide={true}
                />
              </div>
            )}
            {recruiterUser && (
              <React.Fragment>
                <MobileCandidateOptions isAdmin={isAdmin} candidate={candidate} />
                <div className="items-center mr-sm ml-5 hidden sm:flex">
                  {!isAdmin && (
                    <img
                      title='Chat'
                      alt=""
                      className="cursor-pointer"
                      onClick={() =>
                        navigate('/chat', { state: { candidate: { id: candidate.id, name: candidate.name, profilePicture: candidate.profilePictureURL, messages: [] } } })
                      }
                      src={ChatIcon}
                      style={{ width: 40, marginRight: 10, marginBottom: 0 }}
                    />
                  )}
                  <Link to={`/candidates/${candidate && candidate.id}/edit`} state={{ candidate }}>
                    <img alt="" src={EditIcon} style={{ width: 40, marginBottom: 0, cursor: 'pointer' }} />
                  </Link>
                  <DeleteUsersButton selectedCandidates={[location.state.candidate.id]} componentType="trash-icon" type="candidate" adminIDs={[candidate && candidate.adminID]} />
                </div>
              </React.Fragment>
            )}
          </div>
          <div className='candidate-actions'>
            {!isAdmin && (
              <Link
                to={`/download-candidates/${candidate && candidate.id}`}
                state={{ candidate }}
                className="danger-button mr-2"
                target="_blank"
              >
                Download Pdf
              </Link>
            )}
            <button
              className="danger-button"
              disabled={inviteLoading ? true : false}
              onClick={() => handleInviteModalOpen()}
            >
              {inviteLoading ? <Loader type="TailSpin" className="flex justify-center" color="#FFFFFF" height={20} width={20} /> : sendInvitationButtonText}
            </button>
            <div className='action-container'>
              {/* Admin Actions */}
              <AdminActions userId={candidate?.adminID} for={USER_ROLES.CANDIDATE} />
              {recruiterUser && (
                <>
                  {isAdmin && <div className={`flex-1 ${isAdmin ? 'lg:hidden' : ''}`} />}
                  {isAgency ? <AgencyMatchCandidate isDisabled={false} candidateID={candidate && candidate.id} /> : isAdmin ? <MatchCandidate candidateID={candidate && candidate.id} /> : null}
                  {/* MatchCandidate */}
                  {/* <AgencyMatchCandidate */}
                </>
              )}
            </div>
          </div>
          {
            recruiterUser && !isAdmin ?
              <div className='mt-2 mb-2 ml-auto'>
                <AddToProjectButton candidate={candidate} />
              </div>
              : null
          }
          <div className="bg-white rounded shadow flex-1 mb-lg flex text-sm" style={{ padding: '30px 35px' }}>
            <div className="flex flex-wrap relative" style={{ maxWidth: '100%' }}>
              {candidate && (
                <CandidateDetailsHeader
                  approvedChanges={approvedChanges}
                  changes={changes}
                  candidate={candidate}
                  recruiterUser={recruiterUser}
                  companyUser={companyUser}
                  mutualInterest={mutualInterest}
                  approveProfileChangeRequest={approveProfileChangeRequest}
                  declineProfileChangeRequest={declineProfileChangeRequest}
                />
              )}
              {candidate &&
                Object.entries(candidate).map(([label, value]) => {
                  const isChanged = changes[label] ? true : false;
                  switch (label) {
                    case 'id':
                      return null;
                    case 'notes':
                      return null;
                    case '__typename':
                      return null;

                    case 'profilePictureURL':
                      return null;
                    case 'resumeFileName':
                      return null;
                    case 'name':
                      return null;
                    case 'email':
                      return null;
                    case 'salaryMax':
                      return null;
                    case 'interestedCity':
                      if (!value && changes && !changes.interestedCity && !approvedChanges.interestedCity) {
                        return null;
                      }

                      let interestedCities = isAdmin
                        ? value
                        : changes && changes.interestedCity
                          ? changes.interestedCity
                          : approvedChanges.interestedCity
                            ? approvedChanges.interestedCity
                            : value;

                      interestedCities = (Array.isArray(interestedCities) ? interestedCities : [interestedCities]).map(({ city }) => city);

                      return (
                        <div className="w-full relative">
                          <div className="flex">
                            <div className="flex-1">
                              <FieldTitle title="Interested Cities" />
                            </div>
                            {!isAdmin && changes && changes.interestedCity && (
                              <div className="flex items-center" style={{ marginTop: 40, marginLeft: 15 }}>
                                <ProfileChangeUI
                                  approveProfileChangeRequest={approveProfileChangeRequest}
                                  declineProfileChangeRequest={declineProfileChangeRequest}
                                  type="interestedCity"
                                />
                              </div>
                            )}
                          </div>
                          <div className="flex flex-wrap">
                            {interestedCities.map((skill) => (
                              <div
                                className={`${changes && changes.interestedCity && (!value || !value.map(({ city }) => city).includes(skill)) && !approvedChanges[label]
                                  ? 'bg-green text-white'
                                  : 'bg-lightgray text-darkgray'
                                  } mt-md shadow px-md flex items-center mr-sm font-medium`}
                                style={{ fontSize: 12, height: 28, borderRadius: 28 }}
                              >
                                {skill}
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    case 'locationPreferences':
                      const realValue = value || {};
                      const locationPreferences = Object.entries(
                        !isAdmin && approvedChanges.locationPreferences
                          ? approvedChanges.locationPreferences
                          : changes && changes.locationPreferences
                            ? changes.locationPreferences
                            : value || {},
                      )
                        .filter(([_, bool]) => bool)
                        .map(([preference]) => preference);
                      return (
                        <div className="w-full relative">
                          <div className="flex">
                            <div className="flex-1">
                              <FieldTitle title="Location Preferences" />
                            </div>
                            {!isAdmin && changes && changes.locationPreferences && (
                              <div className="flex items-center" style={{ marginTop: 40, marginLeft: 15 }}>
                                <ProfileChangeUI
                                  approveProfileChangeRequest={approveProfileChangeRequest}
                                  declineProfileChangeRequest={declineProfileChangeRequest}
                                  type="locationPreferences"
                                />
                              </div>
                            )}
                          </div>
                          <div className="flex flex-wrap">
                            {locationPreferences.map((skill) => {
                              return (
                                <div
                                  className={`${changes && changes.locationPreferences && realValue[skill] !== changes.locationPreferences[skill] && !approvedChanges[label]
                                    ? 'bg-green text-white'
                                    : 'bg-lightgray text-darkgray'
                                    } mt-md shadow px-md flex items-center mr-sm font-medium`}
                                  style={{ fontSize: 12, height: 28, borderRadius: 28 }}
                                >
                                  {skill === 'remote' ? 'Remote' : skill === 'willingToRelocate' ? 'Willing To Relocate' : 'Current Location'}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    case 'resumeURL':
                      return null;
                    case 'salaryMin':
                      const salaryMin = changes && changes.salarymin ? changes.salarymin : approvedChanges.salaryMin ? approvedChanges.salaryMin : candidate.salaryMin;
                      // const salaryMax = changes && changes.salarymax ? changes.salarymax : approvedChanges.salaryMax ? approvedChanges.salaryMax : candidate.salaryMax;
                      return (
                        <div className=" flex flex-col font-medium w-1/2">
                          <div className="flex items-center capitalize text-darkgray pb-md">
                            Salary
                            {!isAdmin && changes && (changes.salarymax || changes.salarymin) && (
                              <div className="flex ml-md">
                                <img
                                  title='Preview'
                                  alt="" src={EyeIcon} style={{ width: 17, height: 17, marginBottom: 0, marginRight: 10 }} />
                                <img
                                  alt=""
                                  onClick={() => {
                                    approveProfileChangeRequest('salary');
                                  }}
                                  src={CheckIcon}
                                  style={{ cursor: 'pointer', position: 'relative', top: 1, width: 15, height: 15, marginBottom: 0, marginRight: 10 }}
                                />
                                <img
                                  alt=""
                                  onClick={() => {
                                    declineProfileChangeRequest('salaryMin');
                                    declineProfileChangeRequest('salaryMax');
                                  }}
                                  src={CloseIcon}
                                  style={{ cursor: 'pointer', position: 'relative', top: 1, width: 15, height: 15, marginBottom: 0, marginRight: 10 }}
                                />
                              </div>
                            )}
                          </div>
                          <div className="text-darkblue pb-xl pr-lg">{salaryMin || salaryMin !== 0 ? `$ ${formatSalary({ salary: salaryMin })} +` : `Negotiable`}</div>
                        </div>
                      );
                    case 'facebook':
                      return (
                        <CandidateSocial
                          value={{ facebook: candidate.facebook, instagram: candidate.instagram, twitter: candidate.twitter }}
                          changes={changes}
                          approvedChanges={approvedChanges}
                          approveProfileChangeRequest={approveProfileChangeRequest}
                          declineProfileChangeRequest={declineProfileChangeRequest}
                        />
                      );
                    case 'instagram':
                      return null;
                    case 'twitter':
                      return null;
                    case 'is_login':
                      return null;
                    case 'bio':
                      return (
                        value && (
                          <div className="w-full">
                            <div className="flex">
                              <div className="flex-1">
                                <FieldTitle title="Short Bio" />
                              </div>
                              {!isAdmin && changes && changes.bio && (
                                <div className="flex items-center" style={{ marginTop: 40, marginLeft: 15 }}>
                                  <ProfileChangeUI approveProfileChangeRequest={approveProfileChangeRequest} declineProfileChangeRequest={declineProfileChangeRequest} type="bio" />
                                </div>
                              )}
                            </div>
                            <div
                              className="text-darkgray mt-sm"
                              style={{ fontSize: 14, color: companyUser && !mutualInterest && 'transparent', textShadow: companyUser && !mutualInterest && '0 0 7px black' }}
                            >
                              {recruiterUser && !isAdmin && (approvedChanges.bio ? approvedChanges.bio : changes.bio ? parse(changes.bio) : parse(value))}
                              {isAdmin && value}
                              {companyUser && mutualInterest && value}
                              {companyUser && !mutualInterest && bioLorem}
                            </div>
                          </div>
                        )
                      );
                    case 'employment':
                      return (
                        <CandidateEmployment
                          approvedChanges={approvedChanges.employment}
                          approveProfileChangeRequest={approveProfileChangeRequest}
                          declineProfileChangeRequest={declineProfileChangeRequest}
                          isAdmin={isAdmin}
                          // value={value}
                          changes={changes && changes.employment}
                          value={typeof value === 'string' ? JSON.parse(value) : value}
                        />
                      );

                    case 'education':
                      return value && value.length > 0 ? (
                        <div className="w-full">
                          <div className="flex">
                            <div className="flex-1">
                              <FieldTitle title="Education" />
                            </div>
                            {!isAdmin && changes && changes.education && JSON.stringify(changes.education) !== JSON.stringify(approvedChanges.education) && (
                              <div className="flex items-center" style={{ marginTop: 40, marginLeft: 15 }}>
                                <ProfileChangeUI
                                  approveProfileChangeRequest={approveProfileChangeRequest}
                                  declineProfileChangeRequest={declineProfileChangeRequest}
                                  type="education"
                                />
                              </div>
                            )}
                          </div>
                          <div className="">
                            {(!isAdmin && changes && changes.education ? changes.education : approvedChanges.education ? approvedChanges.education : value).map((entry) => (
                              <EducationEntry entry={entry} />
                            ))}
                          </div>
                        </div>
                      ) : null;

                    case 'certifications':
                      return (
                        value &&
                        value.length > 0 && (
                          <div className="w-full">
                            <FieldTitle title="Certifications & Licences" />
                            <div className="flex flex-wrap">
                              {value.map((skill) => (
                                <div
                                  className="bg-lightgray mt-md shadow px-md text-darkgray flex items-center mr-sm font-medium"
                                  style={{ fontSize: 12, height: 28, borderRadius: 28, color: '#9499a2' }}
                                >
                                  {skill}
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                      );

                    case 'attributes':
                      return (
                        <div className="w-full relative">
                          <div className="flex">
                            <div className="flex-1">
                              <FieldTitle title="Candidate Attributes" />
                            </div>
                            {!isAdmin && changes && changes.attributes && (
                              <div className="flex items-center" style={{ marginTop: 40, marginLeft: 15 }}>
                                <ProfileChangeUI
                                  approveProfileChangeRequest={approveProfileChangeRequest}
                                  declineProfileChangeRequest={declineProfileChangeRequest}
                                  type="attributes"
                                />
                              </div>
                            )}
                          </div>
                          <div className="flex flex-wrap">
                            {(!isAdmin && changes && changes.attributes ? changes.attributes : approvedChanges[label] ? approvedChanges[label] : value || []).map(
                              (skill, index) => (
                                <div
                                  key={index}
                                  className={`${changes && changes.attributes && !value.includes(skill) && !approvedChanges[label] ? 'bg-green text-white' : 'bg-lightgray text-darkgray'
                                    } mt-md shadow px-md flex items-center mr-sm font-medium`}
                                  style={{ fontSize: 12, height: 28, borderRadius: 28 }}
                                >
                                  {skill}
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      );

                    case 'preferredSkills':
                      return (
                        <div className="w-full relative">
                          <div className="flex">
                            <div className="flex-1">
                              <FieldTitle title="Job Preferences" />
                            </div>
                            {!isAdmin && changes && changes.preferredSkills && (
                              <div className="flex items-center" style={{ marginTop: 40, marginLeft: 15 }}>
                                <ProfileChangeUI
                                  approveProfileChangeRequest={approveProfileChangeRequest}
                                  declineProfileChangeRequest={declineProfileChangeRequest}
                                  type="preferredSkills"
                                />
                              </div>
                            )}
                          </div>
                          <div className="flex flex-wrap">
                            {(!isAdmin && changes && changes.preferredSkills ? changes.preferredSkills : approvedChanges[label] ? approvedChanges[label] : value).map((skill) => (
                              <div
                                className={`${changes && changes.preferredSkills && !value.includes(skill) && !approvedChanges[label] ? 'bg-green text-white' : 'bg-lightgray text-darkgray'
                                  } mt-md shadow px-md flex items-center mr-sm font-medium`}
                                style={{ fontSize: 12, height: 28, borderRadius: 28 }}
                              >
                                {skill}
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    default:
                      return (
                        <div className=" flex flex-col font-medium w-1/2">
                          <div className="flex items-center capitalize text-darkgray pb-md">
                            {label === 'recruiter'
                              ? 'Recruiter Name'
                              : label === 'referralName'
                                ? 'Referral Name'
                                : label === 'loxo_id'
                                  ? 'Loxo ID'
                                  : label === 'invite_code'
                                    ? 'Invite Code'
                                    : label === 'invite_deeplink'
                                      ? 'Invite Deep Link'
                                      : label === 'invite_code_used_on'
                                        ? 'Invite Code used on'
                                        : label === 'recruiter_id'
                                          ? 'Recruiter ID'
                                          : label === 'resumeFileName'
                                            ? 'Resume'
                                            : label === 'salaryMin'
                                              ? 'Salary'
                                              : label === 'approved'
                                                ? ''
                                                : label === 'adminID'
                                                  ? ''
                                                  : label}
                            {isChanged && !approvedChanges[label] && (
                              <div className="flex ml-md">
                                <img alt=""
                                  title='Preview'
                                  src={EyeIcon} style={{ width: 17, height: 17, marginBottom: 0, marginRight: 10 }} />
                                <img
                                  alt=""
                                  onClick={() => approveProfileChangeRequest(label)}
                                  src={CheckIcon}
                                  style={{ cursor: 'pointer', position: 'relative', top: 1, width: 15, height: 15, marginBottom: 0, marginRight: 10 }}
                                />
                                <img
                                  alt=""
                                  onClick={() => declineProfileChangeRequest(label)}
                                  src={CloseIcon}
                                  style={{ cursor: 'pointer', position: 'relative', top: 1, width: 15, height: 15, marginBottom: 0, marginRight: 10 }}
                                />
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              color: companyUser && !mutualInterest && (label === 'linkedin' || label === 'phone') && 'transparent',
                              textShadow: companyUser && !mutualInterest && (label === 'linkedin' || label === 'phone') && '0 0 7px black',
                            }}
                            className={`text-darkblue pb-xl pr-lg ${label === 'resumeFileName' ? 'hover:underline cursor-pointer' : ''}`}
                          >
                            {renderValue(
                              label,
                              approvedChanges[label]
                                ? approvedChanges[label]
                                : changes[label]
                                  ? changes[label]
                                  : companyUser && !mutualInterest && label === 'linkedin'
                                    ? 'linkedin'
                                    : companyUser && !mutualInterest && label === 'phone'
                                      ? '(555) 555-5555'
                                      : label == 'linkedin'
                                        ? prepareUrl(value)
                                        : value,
                            )}
                          </div>
                        </div>
                      );
                  }
                })}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full" style={{ maxWidth: 767 }}>
          <InterestedJobs isAdmin={isAdmin} setModal={setModal} candidate={candidate} candidateID={location.state.candidate.id} />
          {!isCompany && <ApprovedCompanies candidateID={location.state.candidate.id} />}
          {!isAdmin && (
            <>
              <div className="text-darkblue mt-md mb-sm font-medium" style={{ fontSize: 21 }}>
                Notes
              </div>
              <AdditionalNotes notes={candidate && candidate.notes ? candidate.notes : []} candidateID={candidate && candidate.id} />
            </>
          )}
        </div>
      </div>

      <Dialog open={isInviteModalOpen} onClose={handleInviteModalClose} scroll="paper" fullWidth>
        <DialogTitle>
          <p style={{ fontFamily: 'montserrat', margin: 0 }}>Send Invitation to {candidate ? candidate.name : ''}</p>
        </DialogTitle>

        <DialogContent dividers>
          <p style={{ paddingBottom: 0, margin: 0, fontFamily: 'montserrat' }}>Subject:</p>
          <TextInput subject={false} error={!inviteEmailSubject} value={inviteEmailSubject} placeholder="Subject" update={(value) => setInviteEmailSubject(value)} />

          <br />

          <p style={{ paddingBottom: 0, margin: 0, fontFamily: 'montserrat' }}>Content:</p>

          <div className="w-full">
            <RichTextArea value={inviteEmailBody} error={!inviteEmailBody} update={(value) => setInviteEmailBody(value)} height={400} />
          </div>
        </DialogContent>

        <DialogActions>
          <button
            className={`bg-lightgray text-black font-medium rounded flex items-center justify-center`}
            style={{ fontSize: 14, height: 40, width: 100, marginLeft: 10, fontFamily: 'montserrat' }}
            onClick={handleInviteModalClose}
          >
            Cancel
          </button>

          <button
            className="danger-button"
            style={{ fontSize: 14, height: 40, width: 100, marginLeft: 10, fontFamily: 'montserrat' }}
            onClick={inviteCandidate}
          >
            Send
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
