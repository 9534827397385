import { REQUEST_STATUSES } from "../utils/constants";
// @include(if: $with_meta) name, employment
export const CANDIDATES_PROJECTION = `
  id
  location
  experience
  name @include(if: $with_meta)
  profilePictureURL @include(if: $with_meta)
  email @include(if: $with_meta)
  salaryMax
  salaryMin
  attributes
  employment
  status
  created_at
  recruiter_id
  locationPreferences
  interestedCity
  is_login
  data_source
  device_token
  invite_code
  is_disabled
  recruiter {
    id
    availability
    email @include(if: $with_meta)
    industries
    name @include(if: $with_meta)
    phone @include(if: $with_meta)
    profilePhoto @include(if: $with_meta)
    title
    permissions
    company {
      id
      name
    }
    position
    candidates_aggregate {
      aggregate {
        count
      }
    }
  }
  fav_candidates {
    company_firebase_id
    candidate_id
  }
  company_candidates {
    company_id
    candidate_id
  }
  company_requests {
    company_id
    status
    isDirectRequest
    unlocked_date
    hired_date
    date
    company {
      id
      adminID
    }
    candidate {
      name
      profilePictureURL
    }
  }
`;

// GQL candidate meta details
export const CANDIDATES_META_PROJECTION = `
  id
  name
  profilePictureURL
  employment
`;

// GQL company request lookup
const COMPANY_REQUEST = `
  company_request(where: { company: { adminID: { _eq: $admin_id } } }) {
    status
    candidate_id
    candidate {
      id
    }
  }
`;

// GQL function query args
const ARGS = `
  search_term: $search_term,
  is_unlocked: $is_unlocked,
  c_is_login: $c_is_login,
  min_exp: $min_exp,
  max_exp: $max_exp,
  min_salary: $min_salary,
  max_salary: $max_salary,
  c_attributes: $c_attributes,
  location_preference_willing_to_relocate: $location_preference_willing_to_relocate,
  location_preference_remote: $location_preference_remote,
  g_lat: $g_lat,
  g_long: $g_long,
  g_radius: $g_radius,
  start_date: $start_date,
  end_date: $end_date,
`;

// GQL function query params
const PARAMS = `
  $with_meta: Boolean!,
  $jobTitleFilter: jsonb,
  $order_by: [candidate_order_by!],
  $admin_id: String,
  $search_term: String,
  $is_unlocked: String,
  $c_is_login: String,
  $min_exp: Int,
  $max_exp: Int,
  $min_salary: Int,
  $max_salary: Int,
  $c_attributes: String,
  $location_preference_willing_to_relocate: String,
  $location_preference_remote: String,
  $g_lat: float8,
  $g_long: float8,
  $g_radius: Int,
  $perPage: Int,
  $offset: Int
  $start_date: String,
  $end_date: String,
`;

/**
 * Candidate salary metrics
 * @param {String} baseQuery base filter
 * @param {Boolean} includeSalaryMetrics include salary metrics
 * @returns {String} GQL salary metrics query
 */
const getSalaryMetricsQuery = (baseQuery = '', includeSalaryMetrics = false) => {
  if (!includeSalaryMetrics) {
    return '';
  }
  const base = baseQuery.substring(0, baseQuery.length - 1);
  const SALARY_METRICS_FILTER = `${base}, salaryMin: {_gt: ${0}} }`;
  return `
    salary_metrics: filter_candidates_aggregate(
      args: {${ARGS}}
      ${SALARY_METRICS_FILTER}
      ) {
        aggregate {
          avg {
            salaryMin
          }
          max {
            salaryMin
          }
          min {
            salaryMin
          }
      }
    }
  `;
}

/**
 * GQL include candidates meta info for saved/purchased.
 * @param {String} [baseQuery] base filter
 * @param {Boolean} includeCandidateMeta include candidate details
 * @returns {String} GQL salary metrics query
 */
const getCandidateMetaQuery = (baseQuery = '', includeCandidateMeta = false) => {
  if (includeCandidateMeta) {
    return '';
  }
  const WHERE = `company_requests: {_and: {status: {_in: ["${REQUEST_STATUSES.approved}", "${REQUEST_STATUSES.accepted}"]}, company: {adminID: {_eq: $admin_id}}}}}`;
  return `
    candidates_meta: filter_candidates(
      args: {${ARGS}}
      where: {${WHERE}
      order_by: $order_by
    ) {
      id
      name
      profilePictureURL
      employment
    }
  `;
}

/**
 * GQL include candidates meta info for saved/purchased.
 * @param {String} [baseQuery] base filter
 * @param {Boolean} includeCandidateMeta include candidate details
 * @returns {String} GQL salary metrics query
 */
const getCandidateJobTitles = (baseQuery = '', includeCandidateMeta = false) => {
  if (includeCandidateMeta) {
    return '';
  }
  const base = baseQuery.substring(0, baseQuery.length - 1);
  const JOB_TITLE_FILTER = `${base}, employment: {_contains: $jobTitleFilter} }`;

  return `
    job_titles: filter_candidates(
      args: {${ARGS}}
      ${JOB_TITLE_FILTER}
      order_by: $order_by
      limit: $perPage
      offset: $offset
    ) {
      id
      employment(path: "[0].title")
  }`;
}

/**
 * Base candidate query to fetch list of candidates.
 * @param {String} BASE_FILTER base candidate filter
 * @param {Boolean} includeCandidateMeta include candidate details
 * @param {Boolean} includeSalaryMetrics include salary metrics
 * @param {String} SALARY_METRICS_FILTER candidate salary metrics
 * @param {String} CANDIDATES_META_FILTER candidates purchased
 * @returns {String} GQL query
 */
export const getCandidateQuery = (
  BASE_FILTER = 'where: { _and: [{ approved: { _eq: true } }, { is_disabled: { _eq: false } }]}',
  includeCandidateMeta = false,
  includeSalaryMetrics = false,
  CANDIDATES_META_FILTER = getCandidateMetaQuery(BASE_FILTER, includeCandidateMeta),
  SALARY_METRICS_FILTER = getSalaryMetricsQuery(BASE_FILTER, includeSalaryMetrics),
  JOB_TITLE_FILTER = getCandidateJobTitles(BASE_FILTER, includeCandidateMeta),
) => `
  query getCandidates(${PARAMS}) {

    filter_candidates(
      args: {${ARGS}}
      ${BASE_FILTER}
      order_by: $order_by
      limit: $perPage
      offset: $offset
      ) {
      ${CANDIDATES_PROJECTION}
    }

    ${JOB_TITLE_FILTER}

    ${CANDIDATES_META_FILTER}

    ${SALARY_METRICS_FILTER}

    filter_candidates_aggregate(
      args: {${ARGS}}
      ${BASE_FILTER}
      ) {
        aggregate {
          count
      }
    }
  }
`;
