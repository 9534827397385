/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation, useSubscription } from '@apollo/react-hooks';
import { Box, Tab, Tabs } from '@material-ui/core';
import { gql } from 'apollo-boost';
import axios from 'axios';
import firebase from 'firebase/app';
import { toast, ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useSnackbar } from 'react-simple-snackbar';
import ReactTooltip from 'react-tooltip';
import { TabTitle } from '../../components/common';
import LoxoFilter from '../../components/Filter/LoxoFilter';
import { GlobalFilterContext } from '../../components/layout/layout';
import { EXPRESS_SERVER_URL } from '../../config';
import { ApolloContext } from '../../context/Apollo';
import getExperienceFromEmployment from '../../utils/getExperienceFromEmployment';
import { generateInviteCode, getId, getLongId } from '../../utils/getId';
import { generateInviteUrl, getCoordinates } from '../../utils/google.helper';
import { getLoxoCandidateDetails } from '../../utils/loxo.helper';
import LoxoModalEdit from '../settings/integrations/loxo-modal-edit';
import LoxoCandidateDetail from './loxo-candidate-detail';
import LoxoCandidateEntry from './loxo-candidate-entry';
import SyncLogs from './sync-logs';
import DatePicker from 'react-datepicker';
import { navigate } from 'gatsby';
import NextArrow from '../../images/next-arrow.png'
import PrevArrow from '../../images/prev-arrow.png'
import ConfirmationModal from '../../components/common/ConfirmationModal';

const ModalContext = React.createContext();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other} style={{ paddingTop: 20 }}>
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
  };
}
const API_RATE_LIMIT = 300;
const MIN_REQUEST_INTERVAL = 9000;
// const MIN_REQUEST_INTERVAL = 2000;
let lastRequestTime = null;

const INSERT_CANDIDATE = gql`
  mutation insertCandidate($candidate: [candidate_insert_input!]!) {
    insert_candidate(objects: $candidate) {
      affected_rows
      returning {
        name
        email
        phone
        profilePictureURL
        linkedin
        recruiter_id
      }
    }
  }
`;

const UPDATE_CANDIDATE = gql`
  mutation updateCandidate($candidateID: String, $changedFields: candidate_set_input) {
    update_candidate(_set: $changedFields, where: { id: { _eq: $candidateID } }) {
      returning {
        name
        email
        phone
        profilePictureURL
        linkedin
        recruiter_id
      }
    }
  }
`;

const GET_BUL_IMPORT_STATUS = gql`
  subscription getSyncLogs($recruiter_id: String, $triggered_by: String, $current_status: String) {
    sync_logs( where: {recruiter_id: {_eq: $recruiter_id}, triggered_by: {_eq: $triggered_by}, current_status: {_eq: $current_status}} ) {
      current_status
      id
    }
  }
`;

export default function LoxoCandidates() {
  const [candidates, setCandidates] = useState();
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  let [pageno, setPageno] = useState(0);

  const [totalpages, setTotalpages] = useState();

  const [scrollids, setScrollids] = useState([]);
  const [nextScrollId, setNextScrollId] = useState();

  const [error, setError] = useState();

  const [dataloading, setDataloading] = useState(false);
  const [importing, setImporting] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [bulkSyncing, setBulkSyncing] = useState(false);
  const [bulkImportSyncingStatus, setBulkImportSyncingStatus] = useState(false);
  const [totalLoxoCount, setTotalLoxoCount] = useState(0);

  const { globalFilter, setGlobalFilter } = useContext(GlobalFilterContext);
  const [filter, setFilter] = useState(globalFilter[`loxo-candidates-filter`] ? globalFilter[`loxo-candidates-filter`] : {});

  const [isAllSelected, setIsAllSelected] = useState(false);
  const [detailModal, setDetailModal] = useState({ data: null, open: false });

  const [auth, setAuth] = useState();
  const { apolloClient } = useContext(ApolloContext);
  const [openSnackbar] = useSnackbar();
  const [openModal, setOpenModal] = useState(false);

  async function getRecords(scrollId, query) {
    setDataloading(true);

    const { data: importerData } = await axios.post(`${EXPRESS_SERVER_URL}/checkImporter`, {
      recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
      account_type: 'loxo',
    });

    axios
      .get(`https://app.loxo.co/api/cherrypicker/people`, {
        params: {
          scroll_id: scrollId,
          query: query,
          person_type_id: '27391',
        },
        headers: {
          Accept: 'application/json',
          Authorization: importerData && importerData[0] && importerData[0].authorization ? importerData[0].authorization : null,
        },
      })
      .then((response) => {
        // console.log({ response });
        if (response.status === 200) {
          setTotalpages(Math.floor(response.data.total_count / 25));
          setTotalLoxoCount(response?.data?.total_count);
          setNextScrollId(response.data ? response.data.scroll_id : '');

          if (importerData && importerData[0] && importerData[0].is_connected) {
            setCandidates(response.data.people);
            setAuth(importerData && importerData[0] && importerData[0].authorization ? importerData[0].authorization : null);
            setTimeout(() => {
              setDataloading(false);
            }, 2000);
          } else {
            setCandidates([]);
            setTimeout(() => {
              setDataloading(false);
            }, 2000);
          }
        }
      })
      .catch((error) => {
        setCandidates([]);
        setDataloading(false);
      });
  }

  function isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  const getQuery = () => {
    let query = '';
    let query1 = null;
    let query2 = null;
    let query3 = null;

    if (!isEmpty(filter)) {
      if (filter['name']) {
        query1 = 'name:' + filter['name'] + '*';
      } else {
        query1 = '';
      }
      if (filter['job_title']) {
        // query2 = `current_title: "${filter['job_title']}"`;
        query2 = `${filter['job_title']
          .split(' ')
          .map((a) => `current_title: ${a}`)
          .join(' OR ')}`;
      } else {
        query2 = '';
      }

      if (filter['start_date'] && filter['end_date']) {
        query3 = 'created_at:[' + moment(new Date(filter['start_date'])).startOf('day').toISOString() + ' TO ' + moment(new Date(filter['end_date'])).endOf('day').toISOString() + ']';
      } else {
        query3 = '';
      }
      let sep1 = '';
      if (query1 && query2) {
        sep1 = ' AND ';
      }
      let sep2 = '';
      if ((query1 && query3) || (query2 && query3)) {
        sep2 = ' AND ';
      }
      query = query1 + sep1 + query2 + sep2 + query3;
    }
    if (query === '') {
      setPageno(0);
    }

    return query;
  };

  useEffect(() => {
    const query = getQuery();

    getRecords(null, query);

    setGlobalFilter({
      ...globalFilter,
      [`loxo-candidates-filter`]: filter,
    });
  }, [filter]);

  function makeApiRequest(nextScrollId, query) {
    const currentTime = new Date().getTime();
    console.log({ currentTime, lastRequestTime, new: (currentTime - lastRequestTime), MIN_REQUEST_INTERVAL });
    if (!lastRequestTime || (currentTime - lastRequestTime) > MIN_REQUEST_INTERVAL) {
      getRecords(nextScrollId, query);
      lastRequestTime = currentTime;
    } else {
      toast('Too many requests. Please wait before making another request.');
    }
  }

  function handlePageClick(type) {
    const query = getQuery();

    if (type === 'next' && totalpages !== pageno) {
      setPageno(++pageno);
      if (!scrollids.includes(nextScrollId)) {
        setScrollids((scrollids) => [...scrollids, nextScrollId]);
      }
      makeApiRequest(nextScrollId, query);
    }
    if (type === 'prev') {
      if (pageno > 0) {
        setPageno(--pageno);
        makeApiRequest(scrollids[pageno - 1], query)
        // getRecords(scrollids[pageno - 1], query);
      }
    }
  }

  const toggleAllCandidate = () => {
    let candIds = [];
    if (selectedCandidates.length > 0) {
      candIds = [];
      setIsAllSelected(false);
    } else {
      candidates.length > 0 && candidates.map((candidate) => candIds.push(candidate));
      setIsAllSelected(true);
      toast.success(`${candIds?.length} records are selected out of ${totalLoxoCount} total records`);
    }
    setSelectedCandidates(candIds);
  };

  const [insertCandidate] = useMutation(INSERT_CANDIDATE);
  const [updateCandidate] = useMutation(UPDATE_CANDIDATE);

  const syncCandidatesNow = async () => {
    ReactTooltip.hide(fooRef);
    toolTipVisible = false;

    setSyncing(true);
    const toastId = toast.dark('Syncing all candidates to Loxo.');

    let data = await fetch(`${EXPRESS_SERVER_URL}/sync-loxo-candidates`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ recruiterId: firebase.auth().currentUser && firebase.auth().currentUser.uid }),
    });

    data = await data.json();
    const { message } = data;

    toast.dismiss(toastId);
    toast.dark(message);

    setSyncing(false);
  };

  const uploadAllCandidate = async () => {
    setImporting(true);

    let candidatesNotImported = [];

    if (selectedCandidates && selectedCandidates.length > 0) {
      for (const candidate of selectedCandidates) {
        // Check if candidate already present in database
        const candidateexist = await apolloClient.query({
          query: gql`
            query checkIfCandidateAlreadyImported {
              candidate(where: { recruiter_id: { _eq: "${firebase.auth().currentUser.uid}" }, loxo_id: { _eq: "${candidate.id.toString()}" } }) {
                id
              }
            }
          `,
          fetchPolicy: 'network-only',
        });
      /*
        if (candidateexist.data.candidate.length > 0) {
          // Update Candidate
          const loxoCandidate = await getLoxoCandidateDetails({ candidateId: candidate.id, auth });
          const employment = loxoCandidate.job_profiles;
          const experience = getExperienceFromEmployment({ employment });
          const location = await getCoordinates({ address: `${candidate.city}, ${candidate.state}` });
          const education = loxoCandidate.education_profiles;

          await updateCandidate({
            variables: {
              candidateID: candidateexist.data.candidate[0].id,
              changedFields: {
                name: candidate.name || candidateexist.data.candidate[0].name,
                email: candidate.emails.length > 0 ? candidate.emails[0].value : '',
                phone: candidate.phones && candidate.phones.length > 0 ? candidate.phones[0].value : '',
                profilePictureURL: candidate.profile_picture_thumb_url || candidateexist.data.candidate[0].profilePictureURL,
                linkedin: candidate.linkedin_url || candidateexist.data.candidate[0].linkedin,
                recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
                attributes: candidate.all_raw_tags && candidate.all_raw_tags.split(',').map((a) => a.trim()),
                salaryMin: candidate.compensation && candidate.compensation !== 0 ? parseInt(candidate.compensation) : 0,
                // created_at: candidate.created_at,
                approved: true,
                experience,
                location: location || candidateexist.data.candidate[0].location,
                loxo_id: candidate.id.toString(),
                employment: employment.map((emp) => {
                  const startDate = moment()
                    .set({ year: emp.year, month: (emp.month || 1) - 1, date: 1 })
                    .startOf('day');

                  let endDate = '';

                  if (emp.end_month) {
                    endDate = moment()
                      .set({ year: emp.end_year, month: (emp.end_month || 1) - 1, date: 1 })
                      .endOf('day');
                  }

                  const newElement = {
                    id: getLongId(),
                    companyLogo: '',
                    companyLocation: '',
                    title: emp.title,
                    companyName: emp.company.name,
                    description: emp.description,
                    tenure: { current: emp.end_month ? false : true, startDate, endDate },
                    skills: [],
                  };

                  return newElement;
                }),
                education: education.map((edu) => {
                  return {
                    id: getLongId(),
                    degree: edu.degree,
                    school: edu.school,
                    tenure: {
                      endDate: moment(`${edu.year}-${edu.month || '01'}-01`)
                        .startOf('month')
                        .format('YYYY-MM-DD'),
                      startDate: '',
                    },
                  };
                }),
                preferredSkills: (candidate.custom_hierarchy_2 || []).map((a) => a.value),
              },
            },
          });
        } else
        */
         if (!candidateexist.data.candidate.length) {
          // Insert Candidate
          const loxoCandidate = await getLoxoCandidateDetails({ candidateId: candidate.id, auth });
          const employment = loxoCandidate.job_profiles;
          const experience = getExperienceFromEmployment({ employment });
          const location = await getCoordinates({ address: `${candidate.city}, ${candidate.state}` });
          const education = loxoCandidate.education_profiles;

          const inviteCode = generateInviteCode();
          const inviteDeepLink = await generateInviteUrl({ inviteCode });

          const candidateEntry = {
            id: getId(),
            name: candidate.name ? candidate.name : '',
            email: candidate.emails.length > 0 ? candidate.emails[0].value : '',
            phone: candidate.phones && candidate.phones.length > 0 ? candidate.phones[0].value : '',
            profilePictureURL: candidate.profile_picture_thumb_url ? candidate.profile_picture_thumb_url : '',
            linkedin: candidate.linkedin_url ? candidate.linkedin_url : '',
            recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
            attributes: candidate.all_raw_tags && candidate.all_raw_tags.split(',').map((a) => a.trim()),
            salaryMin: candidate.compensation && candidate.compensation !== 0 ? parseInt(candidate.compensation) : 0,
            // created_at: candidate.created_at,
            created_at: new Date().toISOString(),
            approved: true,
            experience,
            location,
            is_login: '0',
            loxo_id: candidate.id.toString(),
            employment: employment.map((emp) => {
              const startDate = moment()
                .set({ year: emp.year, month: (emp.month || 1) - 1, date: 1 })
                .startOf('day');

              let endDate = '';

              if (emp.end_month) {
                endDate = moment()
                  .set({ year: emp.end_year, month: (emp.end_month || 1) - 1, date: 1 })
                  .endOf('day');
              }

              const newElement = {
                id: getLongId(),
                companyLogo: '',
                companyLocation: '',
                title: emp.title,
                companyName: emp.company.name,
                description: emp.description,
                tenure: { current: emp.end_month ? false : true, startDate, endDate },
                skills: [],
              };

              return newElement;
            }),
            education: education.map((edu) => {
              return {
                id: getLongId(),
                degree: edu.degree,
                school: edu.school,
                tenure: {
                  endDate: moment(`${edu.year}-${edu.month || '01'}-01`)
                    .startOf('month')
                    .format('YYYY-MM-DD'),
                  startDate: '',
                },
              };
            }),
            invite_code: inviteCode,
            invite_deeplink: inviteDeepLink,
            invite_code_used_on: null,
            preferredSkills: (candidate.custom_hierarchy_2 || []).map((a) => a.value),
          };

          // Default candidates will have remote preselected
          if (candidateEntry?.locationPreferences) {
            candidateEntry.locationPreferences.remote = true;
          }
          // If location preference is null or not defined, Set remote as true and other to false
          if (!candidateEntry?.locationPreferences) {
            candidateEntry.locationPreferences = {
              remote: true,
              currentLocation: true,
              willingToRelocate: false,
            }
          }

          await insertCandidate({
            variables: {
              candidate: candidateEntry,
            },
          });
        }
      }
    }

    if (candidatesNotImported.length) {
      // Will disappear after x seconds. x = 2 seconds for each candidate not imported
      openSnackbar(`Following candidates already exists in our database: ${candidatesNotImported.join(', ')}`, 5 * 1000 + candidatesNotImported.length * 2 * 1000);
    }

    candidatesNotImported = [];

    setImporting(false);
  };

  let fooRef;
  let toolTipVisible = false;
  let tooltipTimer;

  const [activeTab, setActiveTab] = React.useState(0);
  const [loxoModal, setLoxoModal] = useState({ data: null, open: false });
  const editLoxoAccount = () => {
    setLoxoModal({ open: true, data: null });
  };
  const handleChangeActiveTab = (event, newValue) => {
    setActiveTab(newValue);
  };
  const recruiter_id = firebase.auth().currentUser && firebase.auth().currentUser.uid;
  const handleBulkClickBtn = async () => {
    const { start_date, end_date } = filter;

    if (!start_date || !end_date) {
      toast.warning('Start and End Dates are Required');
      return;
    }
    setOpenModal(true);
  }

  const bulkImportCandidatesFromLoxo = async () => {
    const { start_date, end_date } = filter;

    if (!start_date || !end_date) {
      toast.warning('Start and End Dates are Required');
      return;
    }
    setBulkSyncing(true);
    try {
      const recruiter_id = firebase.auth().currentUser && firebase.auth().currentUser.uid

      const { data: importerData } = await axios.post(`${EXPRESS_SERVER_URL}/checkImporter`, {
        recruiter_id,
        account_type: 'loxo',
      });

      const email = firebase?.auth()?.currentUser?.email || 'cjtufano@getcherrypicker.com';
      const name = firebase?.auth()?.currentUser?.displayName || email.split('@')[0];
      try {
        const res = await axios.post(`${EXPRESS_SERVER_URL}/import-bulk-candidate`, {
          "recruiter_id": recruiter_id,
          "startDate": moment(start_date).format('YYYY-MM-DD'),
          "endDate": moment(end_date).format('YYYY-MM-DD'),
          "email": email,
          "name": name
        }, {
          headers: {
            Accept: 'application/json',
            Authorization: importerData && importerData[0] && importerData[0].authorization ? importerData[0].authorization : null,
          },
        })
        if (res?.data?.status === 'success') {
          toast.success('Bulk candidates import initiated');
          setBulkImportSyncingStatus(true);
        }
      } catch (error) {
        toast.error('Error In Bulk Import');
      } finally {
        setBulkSyncing(false);
      }
    } catch (error) {
      toast.error('Error In Bulk Import');
      setBulkSyncing(false);
    }
  }

  const { data: syncLogsStatus, loading: syncLogsLoading } = useSubscription(GET_BUL_IMPORT_STATUS, {
    variables: {
      recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
      triggered_by: "bulk-import",
      current_status: "RUNNING"
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (syncLogsStatus?.sync_logs.length > 0) {
      setBulkImportSyncingStatus(true);
    }
    setBulkImportSyncingStatus(false);
  }, [syncLogsStatus, syncLogsLoading]);

  return (
    <div className="w-full flex-1 candidates">
      <ModalContext.Provider>
        <br />
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: '1px solid gray' }}>
            <Tabs value={activeTab} onChange={handleChangeActiveTab}>
              <Tab label="Candidates" {...a11yProps(0)} style={{ fontFamily: 'montserrat', fontSize: 16, fontWeight: 600, outline: 'none' }} disableFocusRipple disableRipple />
              <Tab label="Logs" {...a11yProps(1)} style={{ fontFamily: 'montserrat', fontSize: 16, fontWeight: 600, outline: 'none' }} disableFocusRipple disableRipple />
            </Tabs>
          </Box>
          <TabPanel value={activeTab} index={0}>
            <div className="relative flex items-center" style={{ marginTop: 20, zIndex: 200 }}>
              <TabTitle>Import candidates</TabTitle>
            </div>
            <LoxoFilter filterState={[filter, setFilter]} type="loxo_candidates" />
            <div className="flex-1 flex flex-col pb-lg relative">
              <div className="flex flex-col" style={{ bottom: 0, right: 0, fontSize: 14, justifyContent: 'center', marginBottom: '10px', width: '100%', textAlign: 'end', paddingRight: '19px' }}>
                <span className="text-darkblue">
                  Total no. of candidates: <strong>{totalLoxoCount}</strong>
                </span>
              </div>
              <div className="relative flex justify-between items-center" style={{ marginBottom: 10 }}>
                <div>
                  {bulkImportSyncingStatus ? <span>Bulk import process is in progress, please refresh page to check status</span> : ""}
                </div>
                <div>
                  <div className="lg:m-0 capitalize flex items-center flex-row justify-between w-full" style={{ fontSize: 21, fontWeight: 500 }}></div>
                  <div className="import_csv flex justify-between lg:mt-0 mt-sm">
                    <button
                      onClick={() => handleBulkClickBtn()}
                      className={`${bulkImportSyncingStatus ? 'bg-darkgray' : 'bg-red'} text-white font-medium rounded flex items-center justify-center`}
                      style={{ fontSize: 14, height: 40, width: 200, marginRight: 10, cursor: 'pointer' }}
                      disabled={bulkImportSyncingStatus}
                    >
                      {bulkSyncing ? <Loader type="TailSpin" className="flex justify-center" color="#fff" height={20} width={20} /> : 'Import Bulk Candidates'}
                    </button>
                    <a
                      ref={(ref) => (fooRef = ref)}
                      data-tip="By clicking the Sync To Loxo Button, your records in Cherrypicker will be overwriting your Loxo records.<br>This may take a few minutes."
                      data-for="sync-to-loxo"
                      data-event="click"
                      data-eventOff="click"
                      data-globalEventOff="click"
                      data-isCapture="true"
                      onClick={syncCandidatesNow}
                      className={`bg-red text-white font-medium rounded flex items-center justify-center`}
                      style={{ fontSize: 14, height: 40, width: 120, marginRight: 2, cursor: syncing ? 'not-allowed' : 'pointer' }}
                    >
                      {syncing ? <Loader type="TailSpin" className="flex justify-center" color="#fff" height={20} width={20} /> : <>Sync To Loxo</>}
                    </a>

                    <p
                      className="font-medium rounded flex items-top justify-center"
                      style={{ marginRight: 15, cursor: 'pointer' }}
                      onClick={() => {
                        if (toolTipVisible) {
                          ReactTooltip.hide(fooRef);
                          toolTipVisible = false;
                        } else {
                          ReactTooltip.show(fooRef);
                          toolTipVisible = true;
                        }
                      }}
                    >
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.00065 14.6667C11.6673 14.6667 14.6673 11.6667 14.6673 8.00004C14.6673 4.33337 11.6673 1.33337 8.00065 1.33337C4.33398 1.33337 1.33398 4.33337 1.33398 8.00004C1.33398 11.6667 4.33398 14.6667 8.00065 14.6667Z"
                          stroke="rgb(227, 28, 37)"
                          stroke-linecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.68652 10.0693L7.67578 9.7793C7.67578 9.26009 7.76351 8.82503 7.93896 8.47412C8.06071 8.23063 8.31673 7.89941 8.70703 7.48047C8.97201 7.19759 9.14567 6.96842 9.22803 6.79297C9.31038 6.61393 9.35156 6.42057 9.35156 6.21289C9.35156 5.81901 9.22624 5.48421 8.97559 5.2085C8.72493 4.93278 8.43669 4.79492 8.11084 4.79492C7.77783 4.79492 7.49495 4.92204 7.26221 5.17627C7.03304 5.4305 6.88444 5.83512 6.81641 6.39014L6 6.27197C6.07878 5.51286 6.30257 4.94531 6.67139 4.56934C7.04378 4.18978 7.52181 4 8.10547 4C8.69271 4 9.18148 4.20231 9.57178 4.60693C9.96566 5.00798 10.1626 5.52181 10.1626 6.14844C10.1626 6.49219 10.0981 6.80729 9.96924 7.09375C9.84033 7.38021 9.58252 7.7347 9.1958 8.15723C8.86637 8.51172 8.66048 8.79281 8.57812 9.00049C8.49935 9.20459 8.45459 9.56087 8.44385 10.0693H7.68652ZM7.63281 12.0083V10.9072H8.53516V12.0083H7.63281Z"
                          fill="rgb(227, 28, 37)"
                        />
                      </svg>
                    </p>

                    <a
                      onClick={uploadAllCandidate}
                      className={`${selectedCandidates.length > 0 ? 'bg-red' : 'bg-darkgray'} text-white font-medium rounded flex items-center justify-center`}
                      style={{ fontSize: 14, height: 40, width: 250, marginRight: 10, cursor: selectedCandidates.length > 0 ? 'pointer' : 'not-allowed' }}
                    >
                      {importing ? <Loader type="TailSpin" className="flex justify-center" color="#fff" height={20} width={20} /> : 'Import Selected Candidates'}
                    </a>
                    <div style={{ display: 'flex', width: 120, marginTop: 10 }}>
                      <div onClick={toggleAllCandidate} className={`${isAllSelected ? 'bg-green' : ''} border border-green cursor-pointer`} style={{ width: 18, height: 18 }} />
                      <div className="text-sm mr-sm" style={{ paddingLeft: 10 }}>
                        Select All
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`flex-1 bg-white rounded shadow candidates-container candidate-blank-dash`} style={{ overflowX: 'auto' }}>
                {candidates && candidates.length > 0 ? (
                  dataloading ? (
                    <div style={{ marginLeft: 600, marginTop: 150 }}>
                      <Loader type="TailSpin" color="#E31C25" height={50} width={50} />
                    </div>
                  ) : (
                    <div style={{ width: 1320, padding: '0px 35px' }}>
                      {candidates.map((candidate) => (
                        <LoxoCandidateEntry
                          key={candidate.id.toString()}
                          candidate={candidate}
                          isSelected={selectedCandidates.includes(candidate)}
                          toggleCandidate={() => {
                            if (selectedCandidates.includes(candidate)) {
                              setSelectedCandidates(selectedCandidates.filter((id) => id !== candidate));
                            } else {
                              setSelectedCandidates([...selectedCandidates, candidate]);
                            }
                          }}
                          state={[setDetailModal]}
                          errorState={[error, setError]}
                          auth={auth}
                        />
                      ))}
                      <LoxoCandidateDetail state={[detailModal, setDetailModal]} />
                    </div>
                  )
                ) : dataloading ? (
                  <div style={{ marginLeft: 600, marginTop: 150 }}>
                    <Loader type="TailSpin" color="#E31C25" height={50} width={50} />
                  </div>
                ) : auth ? (
                  <div className={`flex-1 bg-white rounded shadow`}>
                    <div className="flex justify-center items-center flex-1" style={{ minHeight: 500, maxHeight: 'calc(100vh - 472px)' }}>
                      <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
                        No Candidates found
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={`flex-1 bg-white rounded shadow`}>
                    <div className="flex justify-center items-center flex-1" style={{ minHeight: 500, maxHeight: 'calc(100vh - 472px)' }}>
                      <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43, textAlign: 'center', cursor: 'pointer' }} onClick={editLoxoAccount}>
                        Looks like you haven't connected your Loxo account.
                        <br />
                        Click here to configure your preferences
                      </div>
                    </div>

                    <LoxoModalEdit
                      state={[loxoModal, setLoxoModal]}
                      onClose={() => {
                        const query = getQuery();

                        getRecords(null, query);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {candidates && candidates.length > 0 && (
              <div style={{ float: 'right', display: 'flex', height: '52px' }}>
                {/* <button
                  onClick={() => handlePageClick('prev')}
                  className={`hover:bg-red hover:text-white text-red border-red flex items-center justify-center px-md rounded border w-full text-xs font-medium`}
                  style={{ height: 40, width: 120, cursor: pageno === 0 ? 'not-allowed' : '' }}
                >
                  Prev
                </button> 
                  <button
                  onClick={() => handlePageClick('next')}
                  className={`hover:bg-red  hover:text-white text-red border-red flex items-center justify-center px-md rounded border w-full text-xs font-medium`}
                  style={{ height: 40, width: 120, cursor: totalpages === pageno ? 'not-allowed' : '' }}
                >
                  Next
               </button>  */}
                <button className='loxo-btn' onClick={() => handlePageClick('prev')} disabled={dataloading}>
                  <img
                    style={{ cursor: pageno === 0 ? 'not-allowed' : 'pointer', height: "52px", width: "67px" }}
                    src={PrevArrow}
                    alt='prev arrow icon'
                  />
                </button>
                <button className='loxo-btn' disabled={dataloading} onClick={() => handlePageClick('next')}>
                  <img
                    style={{ cursor: totalpages === pageno ? 'not-allowed' : 'pointer', height: "52px", width: "67px" }}
                    src={NextArrow}
                    alt='next arrow icon'
                  />
                </button>
              </div>
            )}
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <SyncLogs />
          </TabPanel>
        </Box>
        {<ConfirmationModal open={openModal} showCancel={true} confirm={bulkImportCandidatesFromLoxo} onChange={(e) => setOpenModal(e)}>
          <p>Are you sure you want to import {totalLoxoCount} candidates from loxo to your Cherrypicker account?</p>
        </ConfirmationModal>}
      </ModalContext.Provider>
      <ToastContainer style={{ width: 'fit-content' }} position="bottom-center" autoClose={3000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      <ReactTooltip
        id="sync-to-loxo"
        place="top"
        effect="solid"
        multiline={true}
        clickable={true}
        afterHide={() => {
          if (tooltipTimer) {
            clearTimeout(tooltipTimer);
          }
          toolTipVisible = false;
        }}
        afterShow={() => {
          toolTipVisible = true;
          tooltipTimer = setTimeout(() => {
            if (toolTipVisible) {
              ReactTooltip.hide(fooRef);
              toolTipVisible = false;
            }
          }, 5 * 1000);
        }}
      />
    </div>
  );
}
