import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { navigate } from 'gatsby';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import FormSlider from '../../components/FormSlider/FormSlider';
import { EXPRESS_SERVER_URL } from '../../config';
import { UserRoleContext } from '../../context';
import { CREATE_CANDIDATE } from '../../mutations';
import { defaultCompanyLogo } from '../../utils/constants';
import { generateInviteCode } from '../../utils/getId';
import { generateInviteUrl } from '../../utils/google.helper';
import CandidateDetailsForm from './CandidateDetailsForm';
import CandidatePreferencesForm from './CandidatePreferencesForm';
import CandidateProfileForm from './CandidateProfileForm';

const GET_RECRUITER = gql`
  query getRecruiter($id: String) {
    recruiter(where: { id: { _eq: $id } }) {
      id
      title
      name
    }
  }
`;

export default function NewCandidateForm() {
  const [resume, setResume] = useState();
  const [candidate, setCandidate] = useState();
  const [addCandidate, { data }] = useMutation(CREATE_CANDIDATE);
  const [userRole] = useContext(UserRoleContext);
  const isAdmin = userRole === 'admin';
  const [uid] = useState(
    Math.random()
      .toString(28)
      .substring(2),
  );

  const [recruiter, setRecruiter] = useState();

  const { data: recruiterResponse } = useQuery(GET_RECRUITER, {
    variables: {
      id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });

  useEffect(() => {
    if (recruiterResponse) {
      setRecruiter(recruiterResponse.recruiter && recruiterResponse.recruiter[0]);
    }
  }, [recruiterResponse]);

  useEffect(() => {
    if (data) {
      navigate(`/candidates/${uid}`, { state: { candidate: { id: uid } } });
    }
  }, [data]);

  const uploadFile = async (file, path) => {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`${path}/${file.name}`);
    const fileURL = await storageRef
      .put(file)
      .then((snapshot) => snapshot.ref.getDownloadURL())
      .catch(() => { });
    return fileURL;
  };

  // async function uploadImage(file, location) {
  //   const storageRef = firebase
  //     .storage()
  //     .ref()
  //     .child(`images/${location}/${file.name}`);
  //   const logoURL = await storageRef
  //     .put(file)
  //     .then(snapshot => snapshot.ref.getDownloadURL());
  //   return logoURL;
  // }

  const createCandidate = async (newCandidate, setLoading) => {
    console.log(`This is in createCandidate function in NewCandidateForm.js`)

    const recruiterID = isAdmin ? newCandidate.recruiter.id : firebase.auth().currentUser.uid;
    const employment = newCandidate.employment.map(async (employment) => {
      const tenure = {
        startDate: employment.tenure.startDate === '' ? '' : new Date(employment.tenure.startDate),
        endDate: employment.tenure.endDate === '' ? new Date() : new Date(employment.tenure.endDate),
        current: employment.tenure.current,
      };

      if (typeof employment.companyLogo === 'object') {
        const companyLogo = employment.companyLogo && employment.companyLogo !== '' ? await uploadFile(employment.companyLogo, 'companylogos') : defaultCompanyLogo;
        return { ...employment, tenure, companyLogo };
      } else {
        return { ...employment, tenure };
      }
    });

    const education = newCandidate.education.map((education) => {
      const tenure = {
        startDate: education.tenure.startDate === '' ? '' : new Date(education.tenure.startDate),
        endDate: education.tenure.endDate === '' ? new Date() : new Date(education.tenure.endDate),
        current: education.tenure.endDate === '' && education.tenure.startDate !== '' ? true : false,
      };
      return { ...education, tenure };
    });

    Promise.all(employment).then(async (employment) => {
      const candidateObject = {
        id: uid,
        name: newCandidate.name,
        email: newCandidate.email,
        phone: newCandidate.phone,
        // profilePictureURL: await uploadImage(newCandidate.profilePictureURL, "avatars"),
        resumeURL: newCandidate.resume.ResumeFileName ? '' : await uploadFile(newCandidate.resume, '/resumes'),
        resumeFileName: newCandidate.resume.name,
        referralName: newCandidate.referralName,
        recruiterID,
        linkedin: newCandidate.linkedin,
        facebook: newCandidate.facebook,
        instagram: newCandidate.instagram,
        twitter: newCandidate.twitter,
        bio: newCandidate.bio,
        location: newCandidate.location,
        experience: newCandidate.experience,
        employment,
        education,
        certifications: newCandidate.certifications,
        attributes: newCandidate.attributes,
        preferredSkills: newCandidate.preferredSkills,
        salaryMax: newCandidate.salary[1],
        salaryMin: newCandidate.salary[0],
        locationPreferences: newCandidate.locationPreferences,
        interestedCity: newCandidate.interestedCity,
        created_at: new Date().toISOString(),
      };

      // Default candidates will have remote preselected
      if (candidateObject?.locationPreferences) {
        candidateObject.locationPreferences.remote = true;
      }
      // If location preference is null or not defined, Set remote as true and other to false
      if (!candidateObject?.locationPreferences) {
        candidateObject.locationPreferences = {
          remote: true,
          currentLocation: false,
          willingToRelocate: false,
        }
      }

      const experience = candidateObject.employment.reduce((totalExperience, { tenure: { startDate, endDate, current } }) => {
        const start = moment(startDate);
        const end = current ? moment() : moment(endDate);
        return totalExperience + end.diff(start, 'months');
      }, 0);

      const user = await fetch(`${EXPRESS_SERVER_URL}/createUserFirebaseAccount`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: newCandidate.email, name: newCandidate.name }),
      }).then((response) => response.json());

      const inviteCode = generateInviteCode();
      const inviteDeepLink = await generateInviteUrl({ inviteCode });

      addCandidate({
        variables: {
          ...candidateObject,
          experience: experience ? Math.floor(experience / 12) : candidateObject.experience,
          approved: true,
          adminID: user.uid,
          invite_code: inviteCode,
          invite_deeplink: inviteDeepLink,
          invite_code_used_on: null,
        },
        refetchQueries: ['getCandidatesByRecruiter'],
      });
    });
  };

  const slides = {
    'Create Candidate Profile': CandidateProfileForm,
    'Candidate Profile': CandidateDetailsForm,
    'Add Job Preferences': CandidatePreferencesForm,
  };

  return (
    <FormSlider
      back={{
        label: 'Candidates',
        link: isAdmin ? '/dashboard/candidates' : '/candidates',
      }}
      action={createCandidate}
      noWhiteBox={1}
      slides={slides}
      // doesnt seem to do anything with resume
      globalFormData={{ resume, setResume, candidate, setCandidate, recruiter, setRecruiter }}
    />
  );
}
